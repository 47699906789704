import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from '@reach/router'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Segment } from 'semantic-ui-react'

import { Home } from '../components/Layout'
import Seo from '../components/Seo'

const StratosImage = require('../assets/images/stratosbanner.png')

const useGridStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '70px',
  },
}))

const useGrid = makeStyles(() => ({
  root: {
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const useLandingGrid = makeStyles(() => ({
  root: {
    alignItems: 'center',
    alignContent: 'left',
    display: 'flex',
    justifyContent: 'left',
  },
}))

const defaultProps = {
  bgcolor: 'background.paper',
  m: 1,
  border: 3,
  style: { width: '5rem', height: '5rem' },
  borderColor: '#03a9f4',
}

const defaultBox = {
  bgcolor: 'background.paper',
  m: 1.8,
  border: 1.8,
  style: { width: '5rem', height: '5rem' },
  borderColor: '#03a9f4',
}

const defaultHome = {
  bgcolor: 'background.paper',
  m: 1,
  border: 3,
  style: { width: '5rem', height: '5rem' },
  borderColor: '#03a9f4',
}

const defaultLanding = {
  bgcolor: 'rgba(255, 255, 255, 0.5)',
  style: { width: '5rem', height: '5rem' },
  m: 1,
  border: 4,
  borderColor: 'black',
}

const Index = () => {
  const gridStyles = useGridStyles()
  const grid = useGrid()
  const landingGrid = useLandingGrid()

  const data = useStaticQuery(graphql`
    query MyQuery {
      product: file(relativePath: { eq: "product.png" }) {
        childImageSharp {
          fluid(maxWidth: 3500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      editor: file(relativePath: { eq: "editor.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chart: file(relativePath: { eq: "chart.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kraken: file(relativePath: { eq: "kraken2.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      coinbase: file(relativePath: { eq: "coinbase.png" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  // TODO(929): do not access process.env directly, first parse
  // whatever information we need at startup and
  const domain = process.env.GATSBY_STRATOS_TESTNET === 'true'
    ? 'https://test.stratos.trade'
    : 'https://www.stratos.trade'

  return (
    <Home>
      <div>
        <Seo
          title={'Stratos | Advanced Trading'}
          description={'Systematic and autonomous trading for cryptocurrency markets'}
          image={`${domain}` + `${StratosImage}`}
          url={domain}
          keywords={[
            'crypto trading bots',
            'cryptocurrency trading bots',
            'crypto bot trading',
            'algorithmic trading',
            'automated crypto trading',
          ]}
        />
        <div className='block'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className='gridLanding'>
          <div className='container homeFont'>Trading Simplified with Stratos</div>
          <div className='container sloganFont' style={{ color: '#343a40' }}>
            Advanced coding terminal to systematically trade your ideas with Python
          </div>
          <br />
          <div className='container'>
            <button
              className='btn btn-buttonBlue btn-lg'
              onClick={async () => navigate('/home')}
            >
              See Demo
            </button>
          </div>
        </div>

        <div className='container'>
          <div>
            <div>
              <Box
                {...defaultLanding}
                borderRadius={10}
                boxShadow={6}
                style={{ width: '70vw', marginTop: '200px' }}
              >
                <div>
                  <Img
                    fluid={data.product.childImageSharp.fluid}
                    alt='Stratos Trading Terminal'
                    style={{ borderRadius: '6px' }}
                  />
                </div>
              </Box>
              <br />
            </div>
          </div>
        </div>

        <div>
          <section
            id='exchanges'
            style={{ backgroundColor: '#868e96', minHeight: 350 }}
          >
            <div className='center'>
              <Grid classes={grid} container xs={12} item={true} spacing={7}>
                <Grid item xs={12}>
                  <div className='about center text-white'>
                    <div>Demo on</div>
                  </div>
                </Grid>
                <Grid item>
                  <Box>
                    <Img
                      fixed={data.coinbase.childImageSharp.fixed}
                      alt='Coinbase Pro Logo'
                      style={{
                        marginBottom: '60px',
                        minHeight: '50px',
                        minWidth: '300px',
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Img
                      fixed={data.kraken.childImageSharp.fixed}
                      alt='Kraken Logo'
                      style={{
                        marginRight: '20px',
                        marginBottom: '60px'
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </div>
          </section>

          <section id='how-it-works' style={{ backgroundColor: 'white' }}>
            <Segment
              vertical
              style={{
                padding: '4em 0em',
                borderTop: '1px solid #f2f2f2',
              }}
            >
              <div>
                <p
                  style={{
                    color: '#03a9f4',
                    fontWeight: 'bold',
                    fontSize: '25px',
                  }}
                  className='container'
                >
                  {' '}
                  CODE{' '}
                </p>
                <div className='container'>
                  <p style={{ color: 'gray' }}>
                    Code your customized strategy or set of rules using Python in the
                    browser. Use Stratos specialized functions to set your exposure on
                    each trade. Find out more from our&nbsp;
                    <a
                      target='_blank'
                      href='https://docs.stratos.trade'
                      rel='noreferrer'
                    >
                      docs
                    </a>
                  </p>
                </div>
                <div className='container'>
                  <div>
                    <Box
                      {...defaultLanding}
                      borderRadius={10}
                      boxShadow={6}
                      style={{ width: '40vw' }}
                    >
                      <Img
                        fluid={data.editor.childImageSharp.fluid}
                        alt='Python Editor'
                        style={{ borderRadius: '6px' }}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <div>
                <p
                  style={{
                    color: '#03a9f4',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    marginTop: '50px',
                  }}
                  className='container'
                >
                  {' '}
                  TEST & DEPLOY{' '}
                </p>
                <div className='container'>
                  <p style={{ color: 'gray' }}>
                    Test and deploy your strategy systematically. Stop it at any time to
                    adjust and redeploy{' '}
                  </p>
                </div>
                <div className='container'>
                  <div>
                    <Box
                      {...defaultLanding}
                      borderRadius={10}
                      boxShadow={6}
                      style={{ width: '40vw' }}
                    >
                      <Img
                        fluid={data.chart.childImageSharp.fluid}
                        alt='Test and Deploy Strategy'
                        style={{ borderRadius: '6px' }}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </Segment>
          </section>

          <div>
            <section id='finale' style={{ backgroundColor: 'white' }}>
              <Segment
                vertical
                style={{
                  padding: '4em 0em',
                  borderTop: '1px solid #f2f2f2',
                }}
              >
                <div className='center'>
                  <Grid classes={grid} container xs={12} item={true} spacing={6}>
                    <Grid item>
                      <p className='getStarted'>
                        Check out our demo today
                      </p>
                      <div
                        className='container sloganFont'
                        style={{ color: '#343a40' }}
                      >
                        View our&nbsp;
                        <a
                          target='_blank'
                          href='https://docs.stratos.trade'
                          rel='noreferrer'
                        >
                          docs
                        </a>
                      </div>
                      <br />
                      <div>
                        <button
                          className='btn btn-stratosBlue btn-lg'
                          onClick={async () => navigate('/home')}
                        >
                          See Demo
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <br />
              </Segment>
            </section>
          </div>
        </div>
      </div>
      <div className="header-card">
        <h1>This is a demo website and not a product for purchase or use</h1>
      </div>
    </Home>
  )
}

export default Index
